.move {
  flex: 0 0 auto;
  position: relative;
  padding: 7px;
  margin-right: 8px;
  border-radius: 0.55rem !important;
  color: #fff !important;
  cursor: pointer;
  max-height: 100px;
  font-size: 12px;
  white-space: nowrap;
}
*:focus {
  outline: none !important;
}
.plan {
  border-radius: 0rem !important;
  color: #fff !important;
}
.draggable {
  cursor: grab;
  -webkit-transition: filter 0.2s; /* Safari */
  transition: filter 0.2s;
}
.dragging {
  filter: drop-shadow(4px 4px 4px grey) brightness(1.2) opacity(0.7);
  transform: rotate(2deg);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.draggable:hover {
  filter: drop-shadow(2px 2px 2px grey) brightness(1.2);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.not-selected {
  filter: opacity(0.3) !important;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.selected {
  filter: drop-shadow(2px 2px 2px grey) !important;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.suggested {
  filter: opacity(0.7);
  border: 0px dotted;
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 35px,
    rgba(255, 255, 255, 0.15) 35px,
    rgba(255, 255, 255, 0.15) 70px
  );
}
.late {
  filter: opacity(0.9);
  border: 0px dotted;
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 10px,
    rgba(255, 255, 255, 0.25) 10px,
    rgba(255, 255, 255, 0.25) 20px
  );
}
.movetype-drive {
  background-color: #2893ef !important;
}
.movetype-drive-pending {
  background-color: #2893ef !important;
  opacity: .7;
}
.movetype-ride-lyft {
  background-color: #ea0b8c !important;
}
.movetype-ride-split {
  background-color: #001242 !important;
}
.movetype-ride-sat {
  background-color: #001242 !important;
}
.movetype-late {
  background-color: #2892ef94 !important;
}
.movetype-canceled {
  background-color: #d60000 !important;
}
.movetype-rejected {
  border: 0px dotted;
  background-color: #d60000 !important;
  opacity: 0.7;
}
.curvedLeftEdges {
  border-top-left-radius: 0.55rem !important;
  border-bottom-left-radius: 0.55rem !important;
}
.curvedRightEdges {
  border-top-right-radius: 0.55rem !important;
  border-bottom-right-radius: 0.55rem !important;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
}
.add-plan {
  flex: 0 0 auto;
  cursor: pointer;
  color: rgba(0, 148, 198, 0.4) !important;
  font-size: 20px;
  padding-top: 5px;
  padding-left: 8px;
  -webkit-transition: color 0.5s; /* Safari */
  transition: color 0.5s;
}
.remove-plan {
  flex: 0 0 auto;
  cursor: pointer;
  color: rgba(0, 148, 198, 0.4) !important;
  font-size: 20px;
  padding-top: 5px;
  padding-left: 8px;
  -webkit-transition: color 0.5s; /* Safari */
  transition: color 0.5s;
}
.add-plan:hover,
.remove-plan:hover,
.add-move:hover {
  color: rgba(0, 148, 198, 1) !important;
  -webkit-transition: color 0.5s; /* Safari */
  transition: color 0.5s;
}
.add-move {
  flex: 0 0 auto;
  cursor: pointer;
  color: rgba(0, 148, 198, 0.4) !important;
  font-size: 43px;
}
.driver {
  height: 60px;
  color: rgba(0, 148, 198, 1) !important;
  font-weight: bold;
}
.timeline-header {
  color: rgba(0, 148, 198, 1) !important;
  font-weight: bold;
}
.move-loc {
  min-height: 1px;
  font-weight: bold;
  margin-top: 0.25rem !important;
}
.move-loc-left {
  padding-left: 15px;
  padding-right: 5px;
}
.move-loc-right {
  padding-left: 5px;
  padding-right: 15px;
  margin-right: 1px;
  text-align: right !important;
}
.move-name {
  width: 30%;
  white-space: nowrap;
  overflow: hidden;
  padding-top: 4px;
  text-overflow: ellipsis;
}
.move-num {
  text-align: center !important;
  flex-basis: 0;
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 1px;
  font-weight: bold;
  font-size: 16px;
}
.lyft-flag {
  position: absolute;
  color: #ea0b8c !important;
  -webkit-transition: color 0.5s; /* Safari */
  transition: color 0.5s;
  z-index: 1000;
  padding: 1px;
  border-radius: 0.25rem !important;
  background-color: #fff !important;
  text-align: center;
  right: 15px;
  top: -8px;
  font-size: 16px;
}

/*
https://iamsteve.me/blog/entry/using-flexbox-for-horizontal-scrolling-navigation
[1]: Make a flex container so all our items align as necessary
[2]: Prevent items from wrapping
[3]: Automatic overflow means a scroll bar won’t be present if it isn’t needed
[4]: Make it smooth scrolling on iOS devices
[5]: Hide the ugly scrollbars in Edge until the scrollable area is hovered
[6]: Hide the scroll bar in WebKit browsers
*/
.plansBlock {
  display: flex; /* [1] */
  flex-wrap: nowrap; /* [1] */
  overflow-x: auto; /* [1] */
  -webkit-overflow-scrolling: touch; /* [4] */
  -ms-overflow-style: -ms-autohiding-scrollbar; /* [5] */
}

.plansBlock::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgb(248, 248, 248);
}

.plansBlock::-webkit-scrollbar {
  height: 6px;
  background-color: rgb(248, 248, 248);
}

.plansBlock::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

  background-color: rgb(206, 206, 206);
}

.add-lane {
  cursor: pointer;
}
.move-dtl-hdr {
  margin-top: -48px !important;
  font-weight: bold;
  text-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
  font-size: 5em;
}

/*******************************************************************/
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.table-text {
  font-size: 14px;
}
#dropdown {
  display: inline= "true" !important;
}

.moveDetailCollapseCard {
  box-shadow: none;
  margin: 15px;
}

.moveDetailCollapseHeader {
  background-color: transparent;
  border-bottom: none;
  padding-left: 0;
}

.input-label-pad-fix {
  margin-top: 10px !important;
}

.input-label-pad-fix label {
  padding-left: 0px !important;
  margin-right: 30px !important;
}

.input-label-pad-fix div,
dl {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.editable-moz-fix div {
  height: 20px !important;
}

.move-details-input {
  margin-left: 10px !important;
  width: 60% !important;
  padding: 6px;
  margin-bottom: 20px;
  margin-top: -10px;
  white-space: pre-wrap;
}

.row-text {
  margin-left: 15px;
  margin-top: -2px;
}
